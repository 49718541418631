import {useState, useEffect, useCallback, useRef} from 'react';

import {
  DataSnapshot,
  getDatabase,
  ref as dbRef,
  set as dbSet,
  onValue as dbOnValue,
  off as dbOff,
} from 'firebase/database';
import {getDatabaseDefaultValue} from '../utils/database';

type FBDataSnapshot = DataSnapshot | null;

type PatientScheduleType = {
  contact_phone: string;
  dob: string;
  gender: string;
  patient_mrn: string;
  patient_name: string;
  provider: string;
  status: boolean;
  visit_reason: string;
  visit_type: string;
  age: string;
  appt_date: string;
  appt_time: string;
};

type PatientSchedulesResult = {
  [key: string]: PatientScheduleType;
};

type Fn<T> = () => T;

const SCHEDULES_SORT = [
  'Holland, Bo',
  'Perkins, Scott',
  'Smith, Jessica',
  'Choe, Jason',
  'Aukerman, Jessica',
  'Kellermann, Betsy',
  'Smith, Steven',
  "O'Connell, Elaine",
  'Marshall, David',
];

export const usePatientSchedules: Fn<
  readonly [
    schedules: PatientSchedulesResult | null,
    resetSchedules: () => void
  ]
> = () => {
  const [schedules, setSchedules] = useState<FBDataSnapshot>(null);

  const dbSchedulesRef = useRef(dbRef(getDatabase(), '/schedules')).current;

  const resetSchedules = useCallback(() => {
    const databaseDefaultValue = getDatabaseDefaultValue();
    if (databaseDefaultValue) {
      dbSet(dbSchedulesRef, databaseDefaultValue);
    }
  }, [dbSchedulesRef]);

  useEffect(() => {
    dbOnValue(dbSchedulesRef, setSchedules);

    return () => dbOff(dbSchedulesRef, 'value', setSchedules);
  }, [dbSchedulesRef]);

  if (schedules) {
    const stringfiedSchedules = JSON.stringify(schedules);
    const schedulesJSON = JSON.parse(
      stringfiedSchedules
    ) as PatientSchedulesResult;

    const sortedJSON = SCHEDULES_SORT.reduce((acc, patientName) => {
      const key = Object.keys(schedulesJSON).filter(
        keys => schedulesJSON[keys].patient_name === patientName
      )[0];
      return {...acc, [key]: schedulesJSON[key]};
    }, {} as PatientSchedulesResult);
    return [sortedJSON, resetSchedules] as const;
  }
  return [null, resetSchedules] as const;
};
