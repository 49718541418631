export const colors = {
  primary: '#0C465E',
  secondary: '#5792A0',
  dark: '#343a40',
  light: '#EBECEB',
  gray: '#A7A6A6',
  success: '#28a745',
  info: '#17a2b8',
  warning: '#ffc107',
  danger: '#dc3545',
  white: '#fff',
  black: '#000',
  softBlack: '#666666',
  mrnHeaderBlue: '#7DD2E5',
  providerBlue: '#5F7AAF',
  tableBlue: '#579EF2',
};
