import React from 'react';
import styled from 'styled-components';
import {colors} from '../config/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 0px 0px 40px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  color: ${colors.black};
`;

interface TabContentContainerProps {
  title: string;
}

const TabContentContainer: React.FC<TabContentContainerProps> = ({
  children,
  title,
}) => (
  <Container>
    <TitleContainer>
      <Title>{title}</Title>
    </TitleContainer>
    {children}
  </Container>
);

export default TabContentContainer;
