const envConfig = process.env;

export default {
  apiKey: envConfig.REACT_APP_API_KEY,
  authDomain: envConfig.REACT_APP_AUTH_DOMAIN,
  databaseURL: envConfig.REACT_APP_DATA_BASEURL,
  projectId: envConfig.REACT_APP_PROJECT_ID,
  storageBucket: envConfig.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: envConfig.REACT_APP_MESSAGING_SENDER_ID,
  appId: envConfig.REACT_APP_APP_ID,
  measurementId: envConfig.REACT_APP_MEASUREMENT_ID,
};
