import React from 'react';
import TabContentContainer from '../../shared/TabContentContainer';

const ChartScreen: React.FC = () => (
  <TabContentContainer title="Chart Screen">
    <div />
  </TabContentContainer>
);

export default ChartScreen;
