import React from 'react';
import TabContentContainer from '../../shared/TabContentContainer';

const InsuranceScreen: React.FC = () => (
  <TabContentContainer title="Insurance Screen">
    <div />
  </TabContentContainer>
);

export default InsuranceScreen;
