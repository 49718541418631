import React from 'react';
import styled from 'styled-components';
import {colors} from '../../../config/theme';

const Container = styled.div`
  position: fixed;
  top: 7.5%;
  left: 5%;
  width: 90%;
  height: 85%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export interface ModalProps {
  patientName: string;
  isOpen: boolean;
  onClose: () => void;
  patientMrn: string;
}

const ModalContentContainer: React.FC = ({children}) => (
  <Overlay>
    <Container>{children}</Container>
  </Overlay>
);

export default ModalContentContainer;
