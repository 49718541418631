import React from 'react';
import TabContentContainer from '../../shared/TabContentContainer';

const PatientScreen: React.FC = () => (
  <TabContentContainer title="Patient Screen">
    <div />
  </TabContentContainer>
);

export default PatientScreen;
