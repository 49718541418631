import React from 'react';
import styled from 'styled-components';
import ModalContentContainer, {ModalProps} from './ModalContentContainer';
import {colors} from '../../../config/theme';
import closeIcon from '../../../../assets/img/closeIcon.svg';
import certifiedIcon from '../../../../assets/img/certifiedIcon.svg';
import reportIcon from '../../../../assets/img/reportIcon.svg';
import nextIcon from '../../../../assets/img/nextIcon.svg';
import previousIcon from '../../../../assets/img/previousIcon.svg';
import viewIcon from '../../../../assets/img/viewIcon.svg';

const Title = styled.h1`
  color: ${colors.mrnHeaderBlue};
  margin-top: 18px;
  margin-left: 40px;
  font-weight: 400;
  color: #7dd2e5;
  font-size: 20px;
`;

const ClickedTitle = styled.h1`
  color: ${colors.white};
  margin-top: 18px;
  margin-left: 40px;
  font-weight: 700;
  font-size: 20px;
`;

const TopBar = styled.div`
  width: 100%;
  height: 58px;
  min-height: 58px;
  background-color: ${colors.primary};
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

const CloseIcon = styled.img.attrs({
  src: closeIcon,
})`
  cursor: pointer;
  width: 24px;
  height: 24px;
  right: 19px;
  top: 16px;
  position: absolute;
`;
const CertifiedIcon = styled.img.attrs({
  src: certifiedIcon,
})`
  width: 140px;
  height: 36px;
  position: absolute;
  right: 40px;
  top: 87px;
`;

const ReportIcon = styled.img.attrs({
  src: reportIcon,
})`
  width: 17px;
  height: 17px;
`;

const ViewIcon = styled.img.attrs({
  src: viewIcon,
})`
  width: 55px;
  height: 30px;
  margin-right: 50px;
`;

const NextIcon = styled.img.attrs({
  src: nextIcon,
})`
  width: 30px;
  height: 30px;
`;

const PreviousIcon = styled.img.attrs({
  src: previousIcon,
})`
  width: 30px;
  height: 30px;
`;

const PatientName = styled.h1`
  font-size: 16px;
  font-weight: 700;
  color: ${colors.black};
  margin: 27px 0 0 40px;
`;

const PatientMrn = styled.h1`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.black};
  margin: 0 0 0 40px;
`;

const Divider = styled.div`
  display: block;
  height: 1px;
  margin: 16px 40px 10px 40px;
  background-color: ${colors.gray};
`;

const TableContainer = styled.div`
  border: 1px solid ${colors.light};
  margin: 0 40px 10px 40px;
  display: flex;
  flex-direction: row;
  color: ${colors.softBlack};
  font-size: 16px;
  font-weight: 700;
  padding: 20px 40px 20px 20px;
  height: 100%;
  justify-content: space-between;
  overflow-y: scroll;
`;

const TableHeaderContainer = styled.div`
  background-color: ${colors.light};
  height: 40px;
  min-height: 40px;
  margin: 10px 40px 0 40px;
  padding: 8px 0 0 10px;
`;

const TableHeader = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin: 0px 0 0 10px;
`;

const TableColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  min-height: 500px;
`;

const ReportColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 500px;
  padding: 8px 0 2px 0;
`;

const ReportIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding-left: 5px;
`;

const TableEntry = styled.h1`
  color: ${colors.softBlack};
  font-size: 16px;
  font-weight: 400;
`;

const TableBlueEntry = styled.h1`
  color: ${colors.providerBlue};
  font-size: 16px;
  font-weight: 700;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-bottom: 15px;
  padding-right: 40px;
`;

const FooterText = styled.h1`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 400;
  margin: 3px 15px 0 15px;
`;

const FormatProvider = (provider: string) => {
  const splitProvider = provider.split(',');
  return `${splitProvider[1]} ${splitProvider[0]}, MD`;
};

interface TestsModalProps extends ModalProps {
  onRenderOverview: () => void;
  provider: string;
}

const TestsModal: React.FC<TestsModalProps> = ({
  isOpen,
  onClose,
  onRenderOverview,
  patientName,
  patientMrn,
  provider,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <ModalContentContainer>
      <TopBar>
        <Title onClick={onRenderOverview}>Patient Overview</Title>
        <ClickedTitle>Test Results</ClickedTitle>
        <Title>Medications</Title>
        <Title>Visits</Title>
        <CloseIcon onClick={onClose} />
      </TopBar>
      <PatientName>{patientName}</PatientName>
      <PatientMrn>MRN {patientMrn}</PatientMrn>
      <CertifiedIcon />
      <Divider />
      <TableHeaderContainer>
        <TableHeader>Test Results</TableHeader>
      </TableHeaderContainer>
      <TableContainer>
        <TableColumn>
          TEST
          <TableEntry>Metabolic, Comprehensive</TableEntry>
          <TableEntry>Pathology, Surgical</TableEntry>
          <TableEntry>MRI Abdomen W PRN Contrast</TableEntry>
          <TableEntry>Chest wall ultrasound - Soft tissue</TableEntry>
          <TableEntry>Strep A, Dna</TableEntry>
          <TableEntry>Strep A, Rapid</TableEntry>
          <TableEntry>Metabolic, Comprehensive</TableEntry>
          <TableEntry>PSA Blood Test</TableEntry>
          <TableEntry>Chest Wall Ultrasound</TableEntry>
          <TableEntry>Chest X-Ray, Routine</TableEntry>
        </TableColumn>
        <TableColumn>
          PROVIDER
          <TableBlueEntry>MD Anderson Lab</TableBlueEntry>
          <TableBlueEntry>MD Anderson Diagnostic Im...</TableBlueEntry>
          <TableBlueEntry>MD Anderson Diagnostic Im...</TableBlueEntry>
          <TableEntry>MockMed Radiology</TableEntry>
          <TableEntry>MockMed Lab</TableEntry>
          <TableEntry>MockMed Lab</TableEntry>
          <TableBlueEntry>Clinical Pathology Labs</TableBlueEntry>
          <TableBlueEntry>Clinical Pathology Labs</TableBlueEntry>
          <TableBlueEntry>Austin Radiological Associ...</TableBlueEntry>
          <TableBlueEntry>Austin Radiological Associ...</TableBlueEntry>
        </TableColumn>
        <TableColumn>
          ORDERED BY
          <TableEntry>John Sullivan, MD</TableEntry>
          <TableEntry>John Sullivan, MD</TableEntry>
          <TableEntry>John Sullivan, MD</TableEntry>
          <TableEntry>{FormatProvider(provider)}</TableEntry>
          <TableEntry>Rober Meyerson, MD</TableEntry>
          <TableEntry>Rober Meyerson, MD</TableEntry>
          <TableEntry>Susan Stevens, MD</TableEntry>
          <TableEntry>Susan Stevens, MD</TableEntry>
          <TableEntry>Susan Stevens, MD</TableEntry>
          <TableEntry>Susan Stevens, MD</TableEntry>
        </TableColumn>
        <TableColumn>
          DATE
          <TableEntry>03/05/2022</TableEntry>
          <TableEntry>03/03/2022</TableEntry>
          <TableEntry>02/23/2022</TableEntry>
          <TableEntry>02/20/2022</TableEntry>
          <TableEntry>11/09/2021</TableEntry>
          <TableEntry>11/09/2021</TableEntry>
          <TableEntry>08/12/2021</TableEntry>
          <TableEntry>08/12/2021</TableEntry>
          <TableEntry>08/12/2021</TableEntry>
          <TableEntry>08/12/2021</TableEntry>
        </TableColumn>
        <ReportColumn>
          REPORT
          <ReportIconContainer>
            <ReportIcon />
            <ReportIcon />
            <ReportIcon />
            <ReportIcon />
            <ReportIcon />
            <ReportIcon />
            <ReportIcon />
            <ReportIcon />
            <ReportIcon />
            <ReportIcon />
          </ReportIconContainer>
        </ReportColumn>
      </TableContainer>
      <FooterContainer>
        <FooterText>View</FooterText>
        <ViewIcon />
        <PreviousIcon />
        <FooterText>1 - 10 of 100</FooterText>
        <NextIcon />
      </FooterContainer>
    </ModalContentContainer>
  );
};

export default TestsModal;
