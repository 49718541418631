import React, {useState} from 'react';
import styled from 'styled-components';
import ModalContentContainer, {ModalProps} from './ModalContentContainer';
import {colors} from '../../../config/theme';
import closeIcon from '../../../../assets/img/closeIcon.svg';
import certifiedIcon from '../../../../assets/img/certifiedIcon.svg';
import TestsModal from './TestsModal';
import boHeadshot from '../../../../assets/img/boHeadshot.svg';
import scottHeadshot from '../../../../assets/img/scottHeadshot.svg';
import adamHeadshot from '../../../../assets/img/adamHeadshot.svg';

const Title = styled.h1`
  color: ${colors.mrnHeaderBlue};
  margin-top: 18px;
  margin-left: 40px;
  font-weight: 400;
  color: #7dd2e5;
  font-size: 20px;
`;

const ClickedTitle = styled.h1`
  color: ${colors.white};
  margin-top: 18px;
  margin-left: 40px;
  font-weight: 700;
  font-size: 20px;
`;

const TopBar = styled.div`
  width: 100%;
  height: 58px;
  min-height: 58px;
  background-color: ${colors.primary};
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

const Headshot = styled.img.attrs({})`
  width: 105px;
  height: 105px;
  margin-left: 40px;
  margin-top: 27px;
  margin-bottom: 27px;
  margin-right: 30px;
`;

const CloseIcon = styled.img.attrs({
  src: closeIcon,
})`
  cursor: pointer;
  width: 24px;
  height: 24px;
  right: 19px;
  top: 16px;
  position: absolute;
`;

const CertifiedIcon = styled.img.attrs({
  src: certifiedIcon,
})`
  width: 140px;
  height: 36px;
  position: absolute;
  right: 40px;
  top: 87px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderText = styled.h1`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 400;
  line-height: 21.8px;
  margin-top: 27px;
  margin-right: 40px;
`;

const Divider = styled.div`
  display: block;
  height: 1px;
  margin: 0px 40px 0px 40px;
  background-color: ${colors.gray};
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 40px;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 40px 0 40px;
`;

const GridBox = styled.div`
  border: 1px solid ${colors.light};
  width: 48.5%;
`;

const GridHeaderContainer = styled.div`
  height: 40px;
  width: 100%;
  background-color: ${colors.light};
  padding: 8px 20px 0 15px;
  display: flex;
  justify-content: space-between;
`;

const GridContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 16px 0px 16px;
  justify-content: space-between;
`;

const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const GridTitle = styled.h1`
  font-weight: 600;
  color: ${colors.black};
  font-size: 20px;
`;

const BlueText = styled.h1`
  font-weight: 400;
  color: ${colors.tableBlue};
  font-size: 16px;
`;

const BlueTextUnderline = styled.h1`
  font-weight: 600;
  color: ${colors.tableBlue};
  font-size: 14px;
  text-decoration-line: underline;
`;

const ColumnHeader = styled.h1`
  color: ${colors.softBlack};
  font-weight: 700;
  font-size: 14px;
`;

const ColumnText = styled.h1`
  color: ${colors.softBlack};
  font-weight: 400;
  font-size: 16px;
`;

const selectHeadshot = (name: string) => {
  switch (name) {
    case 'Holland, Bo':
      return boHeadshot;
    case 'Perkins, Scott':
      return scottHeadshot;
    default:
      return adamHeadshot;
  }
};

const calculateAge = (birthday: string) => {
  const splitBirthday = birthday.split('/');
  const day = parseInt(splitBirthday[1], 10);
  const month = parseInt(splitBirthday[0], 10);
  const year = parseInt(splitBirthday[2], 10);
  const today = new Date();
  let age = today.getFullYear() - year;
  if (
    today.getMonth() < month ||
    (today.getMonth() === month && today.getDate() < day)
  ) {
    age -= 1;
  }
  return age;
};

interface MrnModalProps extends ModalProps {
  patientDob: string;
  date: string;
  provider: string;
}

const MrnModal: React.FC<MrnModalProps> = ({
  isOpen,
  onClose,
  patientName,
  patientMrn,
  date,
  provider,
  patientDob,
}) => {
  const [renderTests, setRenderTests] = useState(false);
  const CloseReset = () => {
    onClose();
    setRenderTests(false);
  };
  if (!isOpen) {
    return null;
  }

  if (!renderTests) {
    return (
      <ModalContentContainer>
        <TopBar>
          <ClickedTitle>Patient Overview</ClickedTitle>
          <Title
            onClick={() => {
              setRenderTests(true);
            }}
          >
            Test Results
          </Title>
          <Title>Medications</Title>
          <Title>Visits</Title>
          <CloseIcon onClick={onClose} />
        </TopBar>
        <HeaderContainer>
          <Headshot src={selectHeadshot(patientName)} />
          <HeaderText>
            <b>{patientName}</b> <br />
            MRN {patientMrn} <br />
            XXX-XX-7256 <br /> Male <br />
            <b>
              DOB {patientDob} ({calculateAge(patientDob)} yrs)
            </b>
          </HeaderText>
          <HeaderText>
            <b>Mobile: 512-479-8812</b> <br />
            Work: 512-654-2985 <br />
            <b> Address: </b> <br />
            6509 Spicewood Springs Road <br /> Austin, TX 78759
          </HeaderText>
          <CertifiedIcon />
        </HeaderContainer>
        <Divider />
        <ScrollContainer>
          <GridContainer>
            <GridBox>
              <GridHeaderContainer>
                <GridTitle>Visits</GridTitle>
                <BlueTextUnderline>View More</BlueTextUnderline>
              </GridHeaderContainer>
              <GridContentContainer>
                <GridColumn>
                  <ColumnHeader>DATE</ColumnHeader>
                  <BlueText>{date}</BlueText>
                  <BlueText>03/12/2021</BlueText>
                  <BlueText>07/11/2020</BlueText>
                  <BlueText>12/17/2019</BlueText>
                </GridColumn>
                <GridColumn>
                  <ColumnHeader>PHYSICIAN</ColumnHeader>
                  <ColumnText>{provider}</ColumnText>
                  <ColumnText>{provider}</ColumnText>
                  <ColumnText>{provider}</ColumnText>
                  <ColumnText>{provider}</ColumnText>
                </GridColumn>
                <GridColumn style={{alignItems: 'end'}}>
                  <ColumnHeader>INSURANCE</ColumnHeader>
                  <ColumnText>101.00</ColumnText>
                  <ColumnText>192.00</ColumnText>
                  <ColumnText>250.00</ColumnText>
                  <ColumnText>101.00</ColumnText>
                </GridColumn>
                <GridColumn style={{alignItems: 'end'}}>
                  <ColumnHeader>SELF PAY</ColumnHeader>
                  <ColumnText>0.00</ColumnText>
                  <ColumnText>25.00</ColumnText>
                  <ColumnText>25.00</ColumnText>
                  <ColumnText>0.00</ColumnText>
                </GridColumn>
              </GridContentContainer>
            </GridBox>
            <GridBox>
              <GridHeaderContainer>
                <GridTitle>Patient and Coverages</GridTitle>
              </GridHeaderContainer>
              <ColumnHeader style={{padding: '16px 0 0 16px'}}>
                {patientName} - Patient is the guarantor
              </ColumnHeader>
              <GridContentContainer>
                <GridColumn>
                  <ColumnHeader style={{marginTop: '-10px'}}>
                    INSURANCE
                  </ColumnHeader>
                  <ColumnText>BCBS</ColumnText>
                  <ColumnHeader>EFFECTIVE</ColumnHeader>
                  <ColumnText>01/15/2019</ColumnText>
                </GridColumn>
                <GridColumn>
                  <ColumnHeader style={{marginTop: '-10px'}}>
                    MEMBER
                  </ColumnHeader>
                  <ColumnText>ZGM860778954</ColumnText>
                  <ColumnHeader>PHONE</ColumnHeader>
                  <ColumnText>800-555-2475</ColumnText>
                </GridColumn>
                <GridColumn>
                  <ColumnHeader style={{marginTop: '-10px'}}>
                    GROUP
                  </ColumnHeader>
                  <ColumnText>BTX210</ColumnText>
                  <ColumnHeader>FAX</ColumnHeader>
                  <ColumnText>800-555-2476</ColumnText>
                </GridColumn>
              </GridContentContainer>
            </GridBox>
          </GridContainer>
          <GridContainer>
            <GridBox>
              <GridHeaderContainer>
                <GridTitle>Statements</GridTitle>
                <BlueTextUnderline>View More</BlueTextUnderline>
              </GridHeaderContainer>
              <GridContentContainer>
                <GridColumn>
                  <ColumnHeader>DATE</ColumnHeader>
                  <BlueText>03/12/2021</BlueText>
                  <BlueText>07/11/2020</BlueText>
                  <BlueText>12/17/2019</BlueText>
                </GridColumn>
                <GridColumn>
                  <ColumnHeader>INVOICE</ColumnHeader>
                  <ColumnText>516841</ColumnText>
                  <ColumnText>515693</ColumnText>
                  <ColumnText>514872</ColumnText>
                </GridColumn>
                <GridColumn style={{alignItems: 'end'}}>
                  <ColumnHeader>CHARGED</ColumnHeader>
                  <ColumnText>101.00</ColumnText>
                  <ColumnText>192.00</ColumnText>
                  <ColumnText>250.00</ColumnText>
                </GridColumn>
                <GridColumn style={{alignItems: 'end'}}>
                  <ColumnHeader>OUTSTD</ColumnHeader>
                  <ColumnText>25.00</ColumnText>
                  <ColumnText>0.00</ColumnText>
                  <ColumnText>0.00</ColumnText>
                </GridColumn>
              </GridContentContainer>
            </GridBox>
            <GridBox>
              <GridHeaderContainer>
                <GridTitle>Payments</GridTitle>
                <BlueTextUnderline>View More</BlueTextUnderline>
              </GridHeaderContainer>
              <GridContentContainer>
                <GridColumn>
                  <ColumnHeader>DATE</ColumnHeader>
                  <BlueText>03/12/2021</BlueText>
                  <BlueText>07/11/2020</BlueText>
                  <BlueText>12/17/2019</BlueText>
                </GridColumn>
                <GridColumn>
                  <ColumnHeader>INVOICE</ColumnHeader>
                  <ColumnText>Co-Payment</ColumnText>
                  <ColumnText>Co-Payment</ColumnText>
                  <ColumnText>Patient Payment</ColumnText>
                </GridColumn>
                <GridColumn style={{alignItems: 'end'}}>
                  <ColumnHeader>CHARGED</ColumnHeader>
                  <ColumnText>25.00</ColumnText>
                  <ColumnText>25.00</ColumnText>
                  <ColumnText>100.00</ColumnText>
                </GridColumn>
                <GridColumn style={{alignItems: 'end'}}>
                  <ColumnHeader>PAYMENT</ColumnHeader>
                  <ColumnText>Credit Card</ColumnText>
                  <ColumnText>Credit Card</ColumnText>
                  <ColumnText>Check</ColumnText>
                </GridColumn>
              </GridContentContainer>
            </GridBox>
          </GridContainer>
        </ScrollContainer>
      </ModalContentContainer>
    );
  }
  return (
    <TestsModal
      isOpen={renderTests}
      provider={provider}
      patientName={patientName}
      patientMrn={patientMrn}
      onRenderOverview={() => setRenderTests(false)}
      onClose={CloseReset}
    />
  );
};

export default MrnModal;
