import React, {useState} from 'react';
import {Table} from 'react-bootstrap';
import styled from 'styled-components';
import {colors} from '../../../config/theme';
import closeIcon from '../../../../assets/img/closeIcon.svg';
import {ModalProps} from './ModalContentContainer';
import DetailModal from './DetailModal';

const ModalContainer = styled.div`
  position: fixed;
  top: 12.5%;
  left: 5%;
  width: 90%;
  height: 75%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Name = styled.h1`
  color: ${colors.white};
  font-size: 20px;
  padding: 17px 20px 0px 40px;
`;

const Mrn = styled.h1`
  color: ${colors.white};
  font-size: 18px;
  text-decoration-line: underline;
  padding-top: 17px;
`;

const Title = styled.h1`
  color: ${colors.black};
  margin 30px 0 10px 40px;
  font-size: 24px;
  font-weight: 400;
`;

const TransactionNumber = styled.span`
  text-decoration-line: underline;
  margin-left: 15px;
`;

const TopBar = styled.div`
  width: 100%;
  height: 58px;
  min-height: 58px;
  background-color: ${colors.primary};
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

const CloseIcon = styled.img.attrs({
  src: closeIcon,
})`
  cursor: pointer;
  width: 24px;
  height: 24px;
  right: 19px;
  top: 16px;
  position: absolute;
`;

const TableContainer = styled.div`
  padding 18px 40px;
  width: 100%;
  height: 75%;
  overflow-y: scroll;
`;

const TableHeader = styled.thead`
  background-color: ${colors.light};
`;

const TableHeaderText = styled.h1`
  font-size: 16px;
  font-weight: 600;
  margin: 2px 0 0 10px;
`;

const TableBody = styled.tbody`
  font-size: 16px;
`;

const TableText = styled.h1`
  padding: 15px 0 15px 0;
  font-size: 16px;
  font-weight: 400;
`;

const deduct = (name: string) => {
  switch (name) {
    case 'Aegrum, Michael':
      return 60;
    case 'Jiang, Adam':
      return 90;
    case 'Aukerman, Jessica':
      return 120;
    case 'Kellerman, Betsy':
      return 150;
    case 'Smith, Steven':
      return 180;
    case "O'Connel, Elaine":
      return 210;
    case 'Marshall, David':
      return 240;
    default:
      return 0;
  }
};

interface ActionModalProps extends ModalProps {
  patientDob: string;
  date: string;
}

const ActionModal: React.FC<ActionModalProps> = ({
  isOpen,
  onClose,
  date,
  patientName,
  patientMrn,
  patientDob,
}) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [transactionNumber, setTransactionNumber] = useState('');

  const timeCoefficient = 1000 * 60 * 30;

  const authorizationTime = new Date();
  const signatureTime = new Date();

  const roundedAuthorizationTime = new Date(
    Math.ceil(authorizationTime.getTime() / timeCoefficient) * timeCoefficient
  );
  const roundedSignatureTime = new Date(
    Math.ceil(signatureTime.getTime() / timeCoefficient) * timeCoefficient
  );

  const addMinutes = (time: Date, minutes: number) => {
    time.setHours(time.getHours(), time.getMinutes() + minutes, 0, 0);
    return time.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  };

  const Next = (val: string) => {
    onClose();
    setIsDetailOpen(true);
    setTransactionNumber(val);
  };

  const getOldDate = (numPreviousDays: number) => {
    const oldDate = new Date();
    oldDate.setDate(oldDate.getDate() - numPreviousDays);
    const day = oldDate.getDate().toString().padStart(2, '0');
    const month = (oldDate.getMonth() + 1).toString().padStart(2, '0');
    const year = oldDate.getFullYear();

    return `${month}/${day}/${year}`;
  };

  if (!isOpen) {
    return (
      <DetailModal
        isOpen={isDetailOpen}
        patientName={patientName}
        patientMrn={patientMrn}
        patientDob={patientDob}
        transactionNumber={transactionNumber}
        onClose={() => setIsDetailOpen(false)}
      />
    );
  }

  return (
    <Overlay>
      <ModalContainer>
        <TopBar>
          <Name>{patientName}</Name>
          <Mrn>MRN {patientMrn}</Mrn>
          <CloseIcon onClick={onClose} />
        </TopBar>
        <Title>Transaction History</Title>
        <TableContainer>
          <Table style={{height: '100%'}}>
            <TableHeader>
              <tr style={{verticalAlign: 'middle'}}>
                <th>
                  <TableHeaderText>Transaction #</TableHeaderText>
                </th>
                <th>Date / Time</th>
                <th>Action</th>
                <th>Context</th>
              </tr>
            </TableHeader>
            <TableBody>
              <tr>
                <td>
                  <TableText>
                    <TransactionNumber>748566</TransactionNumber>
                  </TableText>
                </td>
                <td>
                  <TableText>
                    {date} <br />
                    {addMinutes(roundedSignatureTime, deduct(patientName) - 8)}
                  </TableText>
                </td>
                <td>
                  <TableText>Signature</TableText>
                </td>
                <td>
                  <TableText>HIPAA privacy consent</TableText>
                </td>
              </tr>
              <tr>
                <td>
                  <TableText>
                    <TransactionNumber onClick={() => Next('601392')}>
                      601392
                    </TransactionNumber>
                  </TableText>
                </td>
                <td>
                  <TableText>
                    {date} <br />
                    {addMinutes(
                      roundedAuthorizationTime,
                      deduct(patientName) - 10
                    )}
                  </TableText>
                </td>
                <td>
                  <TableText>Authorization</TableText>
                </td>
                <td>
                  <TableText>Check-in request</TableText>
                </td>
              </tr>
              <tr>
                <td>
                  <TableText>
                    <TransactionNumber>592855</TransactionNumber>
                  </TableText>
                </td>
                <td>
                  <TableText>
                    {getOldDate(2)} <br /> 2:00 PM
                  </TableText>
                </td>
                <td>
                  <TableText>Authentication</TableText>
                </td>
                <td>
                  <TableText>Log in to MockMed patient portal</TableText>
                </td>
              </tr>
              <tr>
                <td>
                  <TableText>
                    <TransactionNumber>435229</TransactionNumber>
                  </TableText>
                </td>
                <td>
                  <TableText>
                    {getOldDate(3)} <br /> 9:42 PM
                  </TableText>
                </td>
                <td>
                  <TableText>Device enrollment</TableText>
                </td>
                <td />
              </tr>
            </TableBody>
          </Table>
        </TableContainer>

        <DetailModal
          isOpen={isDetailOpen}
          patientName={patientName}
          patientMrn={patientMrn}
          patientDob={patientDob}
          transactionNumber={transactionNumber}
          onClose={() => setIsDetailOpen(false)}
        >
          <Title>child</Title>
        </DetailModal>
      </ModalContainer>
    </Overlay>
  );
};

export default ActionModal;
