import React from 'react';
import styled from 'styled-components';

const Label = styled.span`
  font-weight: 700;
`;

const ContentContainer = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 4px 7px 4px 14px;
  margin-left: 15px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface LabeledContainerProps extends React.HTMLAttributes<HTMLElement> {
  label: string;
}

const LabeledContainer: React.FC<LabeledContainerProps> = ({
  children,
  label,
  ...htmlProps
}) => (
  <Container>
    <Label>{label}</Label>
    <ContentContainer {...htmlProps}>{children}</ContentContainer>
  </Container>
);

export default LabeledContainer;
