import React, {useState, useEffect} from 'react';
import {Badge, Table} from 'react-bootstrap';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {useHistory, useLocation} from 'react-router-dom';
import {colors} from '../../config/theme';
import {usePatientSchedules} from '../../hooks/usePatientSchedules';
import LabeledContainer from '../../shared/LabeledContainer';
import TabContentContainer from '../../shared/TabContentContainer';
import {addMinutes, getRoundedTime} from '../../utils/date';
import Icon from '../../shared/Icon';
import ActionsSVG from '../../../assets/img/actionsIcon.svg';
import ActionModal from './Modal/ActionModal';
import MrnModal from './Modal/MrnModal';

const TableContainer = styled.div`
  padding 18px 40px;
  width: 100%;
  min-width: 1210px;
  margin-left: 0px;
  margin-right: auto;
`;

const Divider = styled.div`
  display: block;
  height: 1px;
  margin: 16px 0 10px 0;
  background-color: ${colors.gray};
`;

const StatusBadge = styled(Badge)<{success: boolean}>`
  width: 108px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({success}) => (success ? colors.white : colors.black)};
  font-weight: normal;
  font-size: 16px;
`;

const TableHeader = styled.thead`
  background-color: ${colors.light};
  font-size: 16px;
  justify-content: right;
`;

const MRNLabel = styled.span`
  text-decoration-line: underline;
`;

const FilterContentContainer = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  padding-top: 2px;
`;

const FilterContainer = styled.div`
  margin-right: 32px;
  margin-bottom: 12px;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FilterIcon = styled(Icon)`
  color: ${colors.gray};
  margin-left: 12px;
  padding: 0px 6px 2px 0px;
`;

const ChevronDownIcon = styled(FilterIcon).attrs({
  name: 'chevron-down',
  size: 15,
})`
  margin-left: 30px;
  padding: 4px;
  color: ${colors.black};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 18px;
`;

const TableBody = styled.tbody`
  font-size: 16px;
`;

const ActionsIcon = styled.img.attrs({
  src: ActionsSVG,
  alt: 'actions',
})`
  cursor: pointer;
`;

const FormatDate = (date: string) => {
  if (typeof date === 'string') {
    const splitDate = date.split('-');
    return `${splitDate[0]}/${splitDate[1]}/${splitDate[2]}`;
  }
  return date;
};

const AppointmentScreen: React.FC = () => {
  const [schedules] = usePatientSchedules();
  const currentDate = new Date();
  const [name, setName] = useState('');
  const [provider, setProvider] = useState('');
  const [mrn, setMrn] = useState('');
  const [dob, setDob] = useState('');
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isMrnOpen, setIsMrnOpen] = useState(false);
  const {pathname} = useLocation();
  const history = useHistory();

  const getCurrentDate = (separator = '-') => {
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    return `${month < 10 ? `0${month}` : `${month}`}${separator}${
      date < 10 ? `0${date}` : `${date}`
    }${separator}${year}`;
  };

  useEffect(() => {
    const linkedMRN = pathname.split('/');
    if (schedules) {
      const getPatient = () => {
        return Object.keys(schedules).find(
          e =>
            schedules[e]?.patient_mrn.toString() ===
            linkedMRN[linkedMRN.length - 1]
        );
      };
      const patient = getPatient();
      if (patient) {
        setIsMrnOpen(true);
        setName(schedules[patient].patient_name);
        setMrn(schedules[patient].patient_mrn);
        setDob(FormatDate(schedules[patient].dob));
        setProvider(schedules[patient].provider);
      }
    }
  }, [schedules, pathname]);

  const apptDate = getCurrentDate();

  if (!schedules) {
    return <div />;
  }

  return (
    <div>
      <TabContentContainer title="Appointment Management">
        <Content>
          <FilterWrapper>
            <FilterContainer>
              <LabeledContainer label="Date">
                <FilterContentContainer>
                  <span>{dayjs(new Date()).format('MM/DD/YYYY')}</span>
                  <FilterIcon name="calendar3" size={18} />
                </FilterContentContainer>
              </LabeledContainer>
            </FilterContainer>
            <FilterContainer>
              <LabeledContainer label="Time">
                <FilterContentContainer>
                  <span>
                    {getRoundedTime(currentDate)} to{' '}
                    {getRoundedTime(
                      addMinutes(
                        currentDate,
                        Object.keys(schedules).length * 30
                      )
                    )}
                  </span>
                  <FilterIcon name="alarm" size={18} />
                </FilterContentContainer>
              </LabeledContainer>
            </FilterContainer>
            <FilterContainer>
              <LabeledContainer label="Office">
                <FilterContentContainer>
                  <span>All Locations</span>
                  <ChevronDownIcon />
                </FilterContentContainer>
              </LabeledContainer>
            </FilterContainer>
            <FilterContainer>
              <LabeledContainer label="Provider">
                <FilterContentContainer>
                  <span>All Providers</span>
                  <ChevronDownIcon />
                </FilterContentContainer>
              </LabeledContainer>
            </FilterContainer>
          </FilterWrapper>
        </Content>
      </TabContentContainer>
      <Divider />
      <TableContainer>
        <Table>
          <TableHeader>
            <tr>
              <th>Patient Name</th>
              <th>MRN</th>
              <th>DOB</th>
              <th>Visit Type</th>
              <th>Provider</th>
              <th>Appt Date</th>
              <th>Appt Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </TableHeader>
          <TableBody>
            {Object.keys(schedules).map((key, index) => {
              const item = schedules[key];
              return (
                <tr
                  key={key}
                  style={{
                    height: 55,
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <td style={{width: 180}}>{item?.patient_name}</td>
                  <td style={{width: 102}}>
                    <MRNLabel
                      onClick={() => {
                        setIsMrnOpen(true);
                        setName(item?.patient_name);
                        setMrn(item?.patient_mrn);
                        setDob(FormatDate(item?.dob));
                        setProvider(item?.provider);
                        history.push(`/clinic/${item?.patient_mrn}`);
                      }}
                    >
                      {item?.patient_mrn}
                    </MRNLabel>
                  </td>
                  <td style={{width: 124}}>{FormatDate(item?.dob)}</td>
                  <td style={{width: 113}}>{item?.visit_type}</td>
                  <td style={{width: 145}}>{item?.provider}</td>
                  <td style={{width: 121}}>
                    {dayjs(currentDate).format('MM/DD/YYYY')}
                  </td>
                  <td style={{width: 114}}>
                    {index === 1
                      ? getRoundedTime(addMinutes(currentDate, 0))
                      : getRoundedTime(addMinutes(currentDate, 30 * index))}
                  </td>
                  <td style={{width: 141}}>
                    <StatusBadge
                      pill
                      bg={item?.status ? 'success' : 'light'}
                      success={item?.status}
                    >
                      {item?.status ? 'Checked-in' : 'Scheduled'}
                    </StatusBadge>
                  </td>
                  <td style={{width: 88}}>
                    <ActionsIcon
                      onClick={() => {
                        setIsActionOpen(true);
                        setName(item?.patient_name);
                        setMrn(item?.patient_mrn);
                        setDob(item?.dob);
                      }}
                      data-testid="action"
                    />
                  </td>
                </tr>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <MrnModal
        isOpen={isMrnOpen}
        patientName={name}
        patientMrn={mrn}
        patientDob={dob}
        provider={provider}
        date={FormatDate(apptDate)}
        onClose={() => {
          setIsMrnOpen(false);
          history.replace('/clinic');
        }}
      />
      <ActionModal
        isOpen={isActionOpen}
        patientName={name}
        patientMrn={mrn}
        patientDob={FormatDate(dob)}
        date={FormatDate(apptDate)}
        onClose={() => setIsActionOpen(false)}
      />
    </div>
  );
};

export default AppointmentScreen;
