export const getAgeByBirthdayDate = (birthdayMMDDYYYY?: string) => {
  if (!birthdayMMDDYYYY) {
    return '';
  }
  const birthDateFormatted = birthdayMMDDYYYY.replace('-', '/');
  const birthDate = new Date(birthDateFormatted);
  const currentDate = new Date();

  return `${currentDate.getFullYear() - birthDate.getFullYear()}`;
};

const getRoundedMinutes = (minutes: number) => (minutes > 30 ? '00' : '30');

export const getRoundedTime = (paramDate: Date) => {
  const date = new Date(paramDate);
  const minutes = date.getMinutes();

  if (minutes > 30) {
    date.setHours(date.getHours() + 1);
  }
  const futureHours = date.getHours();
  const formattedFutureHours =
    futureHours > 12 ? futureHours - 12 : futureHours;

  return `${
    formattedFutureHours < 10 ? '0' : ''
  }${formattedFutureHours}:${getRoundedMinutes(minutes)} ${
    futureHours >= 12 ? 'PM' : 'AM'
  }`;
};

export const addHours = (date: Date, hours: number) => {
  const result = new Date(date);
  result.setHours(result.getHours() + hours);
  return result;
};

export const addMinutes = (date: Date, minutes: number) => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() + minutes);
  return result;
};
