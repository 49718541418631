import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';
import AppointmentScreen from '../pages/Appointments';
import ChartScreen from '../pages/Chart';
import DashboardScreen from '../pages/Dashboard';
import InsuranceScreen from '../pages/Insurance';
import PatientScreen from '../pages/Patients';

import SchedulerScreen from '../pages/Scheduler';
import StatementScreen from '../pages/Statements';
import Icon from '../shared/Icon';

interface CustomTabOptionProps {
  label: string;
  icon: string;
  iconProps?: HTMLAttributes<HTMLElement>;
}

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: -3px 7px -3px 0px;
`;

const CustomTabOptionIcon = styled(Icon)`
  margin-right: 7px;
`;

const CustomTabOption: React.FC<CustomTabOptionProps> = ({
  label,
  icon,
  iconProps = {},
}) => (
  <OptionContainer>
    <CustomTabOptionIcon name={icon} size={20} {...iconProps} /> {label}
  </OptionContainer>
);

export const homeTabOptions = [
  {
    eventKey: 'dashboard',
    title: (
      <CustomTabOption
        icon="grid"
        label="Dashboard"
        iconProps={{style: {paddingLeft: 0}}}
      />
    ),
    renderComponent: <DashboardScreen />,
  },
  {
    eventKey: 'patients',
    title: <CustomTabOption icon="person" label="Patients" />,
    renderComponent: <PatientScreen />,
  },
  {
    eventKey: 'appointment',
    title: <CustomTabOption icon="clipboard-check" label="Appt Management" />,
    renderComponent: <AppointmentScreen />,
  },
  {
    eventKey: 'schedule',
    title: <CustomTabOption icon="calendar3" label="Scheduler" />,
    renderComponent: <SchedulerScreen />,
  },
  {
    eventKey: 'chart',
    title: <CustomTabOption icon="folder2-open" label="Chart" />,
    renderComponent: <ChartScreen />,
  },

  {
    eventKey: 'insurance',
    title: <CustomTabOption icon="shield-check" label="Insurance" />,
    renderComponent: <InsuranceScreen />,
  },

  {
    eventKey: 'statements',
    title: (
      <CustomTabOption icon="layout-text-window-reverse" label="Statements" />
    ),
    renderComponent: <StatementScreen />,
  },
];
