import React from 'react';
import styled from 'styled-components';
import {Navbar, Nav} from 'react-bootstrap';
import {useHistory, useLocation} from 'react-router-dom';
import MockMedLogoSVG from '../../assets/img/mockmed_logo.svg';
import Icon from './Icon';

import {colors} from '../config/theme';

const HeaderContainer = styled.div`
  width: 100%;
  padding: 0px 40px;
  background-color: ${colors.primary};
`;

const BrandTitle = styled.h1`
  margin: 0 20px 0 0;
  color: ${colors.white};
  font-weight: 700;
`;

const MockMedLogo = styled.img.attrs({
  src: MockMedLogoSVG,
  alt: 'logo-mockmed',
})`
  cursor: pointer;
`;

const NavOptionLabel = styled.h1<{selected?: boolean}>`
  color: ${colors.white};
  margin: 0 20px 0 0;

  font-weight: 600;
  ${({selected}) =>
    selected && `margin-top: 0px; border-bottom: 1px solid ${colors.white}`};
`;

const Spacer = styled.div`
  width: 120px;
  min-width: 40px;
`;

const SearchContainer = styled.div`
  width: 160px;
  flex-direction: row;
  display: flex;
  margin-right: 40px;
`;

const SearchBarContainer = styled.div`
  width: 138px;
  background-color: ${colors.white};
  border-radius: 5px 0px 0px 5px;
  height: 30px;
  align-items: center;
  display: flex;
  font-color: ${colors.black};
  padding: 2px 0 0 14px;
  font-size: 14px;
  border: 1px solid #dcdcdc;
`;

const SearchIconContainer = styled.div`
  width: 34px;
  height: 30px;
  background-color: ${colors.secondary};
  border-radius: 0px 5px 5px 0px;
  padding: 2px 1px 0px 8px;
`;

const SearchIcon = styled(Icon).attrs({
  name: 'search',
  size: 18,
})`
  color: ${colors.white};
`;

const Header: React.FC<{onClickLogo?: () => void}> = ({onClickLogo}) => {
  const history = useHistory();
  const {pathname} = useLocation();

  return (
    <HeaderContainer>
      <Navbar bg="primary" expand="lg">
        <Navbar.Brand href="/">
          <BrandTitle>DEMO EHR</BrandTitle>
        </Navbar.Brand>
        <Nav.Link onClick={() => history.push('clinic')}>
          <NavOptionLabel selected={pathname === '/clinic'}>
            CLINIC
          </NavOptionLabel>
        </Nav.Link>
        <Nav.Link onClick={() => history.push('billing')}>
          <NavOptionLabel selected={pathname === '/billing'}>
            BILLING
          </NavOptionLabel>
        </Nav.Link>
        <Nav.Link onClick={() => history.push('reports')}>
          <NavOptionLabel selected={pathname === '/reports'}>
            REPORTS
          </NavOptionLabel>
        </Nav.Link>
        <Spacer />
        <SearchContainer>
          <SearchBarContainer>Search</SearchBarContainer>
          <SearchIconContainer>
            <SearchIcon />
          </SearchIconContainer>
        </SearchContainer>

        <Navbar.Collapse className="justify-content-end">
          <MockMedLogo onClick={onClickLogo} />
        </Navbar.Collapse>
      </Navbar>
    </HeaderContainer>
  );
};

export default Header;
