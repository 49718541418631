import React from 'react';
import TabContentContainer from '../../shared/TabContentContainer';

const DashboardScreen: React.FC = () => (
  <TabContentContainer title="Dashboard Screen">
    <div />
  </TabContentContainer>
);

export default DashboardScreen;
