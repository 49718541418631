import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';

import styled from 'styled-components';
import {homeTabOptions} from './config/tabs';
import {usePatientSchedules} from './hooks/usePatientSchedules';
import Header from './shared/Header';
import TabNavigator from './shared/TabNavigator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const App: React.FC = () => {
  // eslint-disable-next-line
  const [_, resetSchedules] = usePatientSchedules();
  return (
    <Router>
      <Container>
        <Header onClickLogo={resetSchedules} />
        <Switch>
          <Route exact path="/">
            <Redirect to="/clinic" />
          </Route>
          <Route path="/clinic">
            <TabNavigator options={homeTabOptions} startIndex={2} />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
};

export default App;
