import React from 'react';
import styled from 'styled-components';

interface CustomIconProps extends React.HTMLAttributes<HTMLElement> {
  name: string;
  size: number;
}

const StyledIcon = styled.i<{size: number}>`
  font-size: ${({size}) => size}px;
`;

const Icon: React.FC<CustomIconProps> = ({
  name,
  size,
  className,
  ...htmlProps
}) => (
  <StyledIcon
    size={size}
    className={`bi bi-${name} ${className || ''}`}
    {...htmlProps}
  />
);

export default Icon;
