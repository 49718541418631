import React from 'react';
import TabContentContainer from '../../shared/TabContentContainer';

const SchedulerScreen: React.FC = () => (
  <TabContentContainer title="Scheduler Screen">
    <div />
  </TabContentContainer>
);

export default SchedulerScreen;
