import React from 'react';
import styled from 'styled-components';
import map from '../../../../assets/img/map.svg';
import mapCloseIcon from '../../../../assets/img/mapCloseIcon.svg';

const MapStyle = styled.img.attrs({
  src: map,
})`
  width: 100%;
  height: 100%;
`;

const CloseIcon = styled.img.attrs({
  src: mapCloseIcon,
})`
  cursor: pointer;
  width: 32px;
  height: 32px;
  right: 36px;
  top: 14px;
  position: absolute;
`;

const MapContainer = styled.div`
  width: 50%;
  height: 75%;
  right: 25%;
  top: 12.5%;
  position: absolute;
`;

interface MapProps {
  isOpen: boolean;
  onCloseMap: () => void;
}

const MapModal: React.FC<MapProps> = ({isOpen, onCloseMap}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <MapContainer>
      <div>
        <MapStyle />
        <CloseIcon onClick={onCloseMap} />
      </div>
    </MapContainer>
  );
};

export default MapModal;
