import React from 'react';
import TabContentContainer from '../../shared/TabContentContainer';

const StatementScreen: React.FC = () => (
  <TabContentContainer title="Statement Screen">
    <div />
  </TabContentContainer>
);

export default StatementScreen;
