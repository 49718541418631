import React, {useState} from 'react';
import styled from 'styled-components';
import {colors} from '../../../config/theme';
import closeIcon from '../../../../assets/img/closeIcon.svg';
import checkmark from '../../../../assets/img/checkmark.svg';
import {ModalProps} from './ModalContentContainer';
import MapModal from './MapModal';

const ModalContainer = styled.div`
  position: fixed;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Title = styled.h1`
  color: ${colors.primary};
  font-size: 16px;
  margin-right: 50px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const BodyColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyTitle = styled.h1`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 700;
  margin-top: 15px;
`;

const BodyText = styled.h1`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 400;
`;

const MapButton = styled.button`
  margin-top: 10px;
  background-color: ${colors.primary};
  color: ${colors.white};
  height: 42px;
  width: 160px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
`;

const TopTitle = styled.h1`
  color: ${colors.white};
  font-size: 18px;
  padding: 17px 15px 0px 40px;
`;

const TopBar = styled.div`
  width: 100%;
  height: 58px;
  min-height: 58px;
  background-color: ${colors.primary};
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

const CloseIcon = styled.img.attrs({
  src: closeIcon,
})`
  cursor: pointer;
  width: 24px;
  height: 24px;
  right: 19px;
  top: 16px;
  position: absolute;
`;

const Checkmark = styled.img.attrs({
  src: checkmark,
})`
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  margin-bottom: 2px;
`;

const Divider = styled.div`
  display: block;
  height: 1px;
  background-color: ${colors.gray};
  position: relative;
  width 100%;
  top: 30px;
`;

const ContentContainer = styled.div`
  padding: 20px 24px 20px 40px;
  margin-right: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

interface DetailModalProps extends ModalProps {
  transactionNumber: string;
  patientDob: string;
}

const DetailModal: React.FC<DetailModalProps> = ({
  isOpen,
  onClose,
  patientName,
  patientMrn,
  transactionNumber,
  patientDob,
}) => {
  const [isMapOpen, setIsMapOpen] = useState(false);
  if (!isOpen) {
    return null;
  }

  return (
    <Overlay>
      <ModalContainer>
        <TopBar>
          <TopTitle>Transaction Number: {transactionNumber}</TopTitle>
          <TopTitle>Action: Authorization</TopTitle>
          <TopTitle>Status: Success</TopTitle>
          <CloseIcon onClick={onClose} />
        </TopBar>
        <ContentContainer>
          <Divider />
          <BodyContainer>
            <BodyColumn>
              <Title>CONTEXT INFORMATION</Title>
              <BodyTitle>Authentication Level</BodyTitle>
              <BodyText>
                Two Factor <br /> (Device + Biometric + Cryptography)
              </BodyText>
              <BodyTitle>Pre-Operation Message</BodyTitle>
              <BodyText>
                Please authorize MockMed to access your
                <br />
                most recent information which includes:
                <br />
                <ul>
                  <li>Medical Record Number</li>
                  <li>Name</li>
                  <li>Address</li>
                  <li>Date of Birth</li>
                  <li>Medical Insurance</li>
                </ul>
              </BodyText>
              <BodyTitle>Data Received</BodyTitle>
              <BodyText>
                MRN: {patientMrn}
                <br />
                Name: {patientName}
                <br />
                Address: 6509 Spicewood Springs Road <br /> Austin, TX 78759{' '}
                <br />
                DOB: {patientDob}
                <br />
                Insurance: BCBS <br />
                Member # ZGM860778954 <br />
                Group # BTX210
              </BodyText>
              <BodyTitle>Post-Operation Message</BodyTitle>
              <BodyText>
                We received your information.
                <br />
                You are now checked in.
              </BodyText>
              <BodyTitle>Status</BodyTitle>
              <BodyText>Success</BodyText>
            </BodyColumn>
            <BodyColumn>
              <Title>LOCATION</Title>
              <BodyTitle>Accuracy</BodyTitle>
              <BodyText>65 meters</BodyText>
              <BodyTitle>Altitude</BodyTitle>
              <BodyText>176.07 meters</BodyText>
              <BodyTitle>Latitude</BodyTitle>
              <BodyText>30.29</BodyText>
              <BodyTitle>Longitude</BodyTitle>
              <BodyText>-97.70</BodyText>
              <MapButton onClick={() => setIsMapOpen(true)}>
                VIEW ON MAP
              </MapButton>
              <MapModal
                isOpen={isMapOpen}
                onCloseMap={() => setIsMapOpen(!isMapOpen)}
              />
            </BodyColumn>
            <BodyColumn>
              <Title>SECURITY</Title>
              <BodyTitle>Cryptographic integrity</BodyTitle>
              <BodyText>
                Dynamic Key Validated
                <Checkmark /> <br />
                Hardware Key Validated
                <Checkmark /> <br />
                Application-layer E2E Crypto
                <Checkmark />
              </BodyText>
              <BodyTitle>Device Authenticity</BodyTitle>
              <BodyText>
                Clone Detection
                <Checkmark /> <br />
                App Attestation
                <Checkmark />
              </BodyText>
              <BodyTitle>Device Information</BodyTitle>
              <BodyText>
                Screen Lock Enabled
                <Checkmark /> <br />
                Device Not Rooted
                <Checkmark />
              </BodyText>
              <BodyTitle>Device ID</BodyTitle>
              <BodyText>4723541b23-c8as-3fse-a7we-234713d62190</BodyText>
              <BodyTitle>Device Manufacturer / Model</BodyTitle>
              <BodyText>Apple iPhone 13.3.1</BodyText>
            </BodyColumn>
          </BodyContainer>
        </ContentContainer>
      </ModalContainer>
    </Overlay>
  );
};

export default DetailModal;
