import DevDatabaseDefaultValues from '../../assets/database/dev.json';
import DemoDatabaseDefaultValues from '../../assets/database/demo.json';
import QADatabaseDefaultValues from '../../assets/database/qa.json';
import SandboxDatabaseDefaultValues from '../../assets/database/sandbox.json';
import UATDatabaseDefaultValues from '../../assets/database/uat.json';

type Environments = 'dev' | 'demo' | 'qa' | 'sandbox' | 'uat';

export const getDatabaseDefaultValue = () => {
  const defaultValues = {
    dev: DevDatabaseDefaultValues,
    demo: DemoDatabaseDefaultValues,
    qa: QADatabaseDefaultValues,
    sandbox: SandboxDatabaseDefaultValues,
    uat: UATDatabaseDefaultValues,
  };

  const environment = process.env
    .REACT_APP_DATABASE_ENVIRONMENT as Environments;

  return defaultValues[environment]?.schedules;
};
